<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <h1>{{ $t('login') }}</h1>
                <p class="text-muted">{{ $t('login_to_your_account') }}</p>
                <CInput
                  placeholder="Email"
                  type="email"
                  trim
                  autofocus
                  autocomplete="email"
                  v-model="email"
                  :isValid="valid_email"
                  v-on:keyup.enter="login()"
                >
                  <template #prepend-content><CIcon name="cil-envelope-closed"/></template>
                </CInput>
                <CInput
                  placeholder="Password"
                  type="password"
                  trim
                  autocomplete="password"
                  v-model="password"
                  :isValid="valid_password"
                  v-on:keyup.enter="login()"
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <CRow>
                  <CCol col="12" sm="6" class="text-left">
                    <CButton class="px-4 text-white" style="background-color: rgb(30, 169, 226);" @click="login()">{{ $t('login') }}</CButton>
                  </CCol>
                  <CCol col="12" sm="6" class="text-right">
                    <CButton color="link" class="px-0">{{ $t('forgot_password') }}</CButton>
                  </CCol>
                </CRow>
                <!-- <hr>
                <CRow>
                  <CCol col="6">
                    <CButton color="dark" title="Sign in with Google" class="px-4 d-flex align-items-center" v-bind="{ variant: 'outline' }"><img alt="Google" title="Sign in with Google" class="mr-2" style="height: 16px;width: 16px;" src="img/google.png">Google</CButton>
                  </CCol>
                  <CCol></CCol>
                </CRow> -->
              </CCardBody>
            </CCard>
            <CCard
              text-color="white"
              class="text-center py-5 d-md-down-none"
              style="background-color: rgb(30, 169, 226);"
              body-wrapper
            >
              <CCardBody>
                <h2>Hihi! 海嗨旅宿</h2>
                <p class="text-muted">Backend Management System</p>
                <p></p>
                <!-- <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                  class="px-4"
                  style="margin-top: 2rem"
                  href="#"
                  target="_blank"
                >
                  Contact us
                </CButton> -->
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'Login',
  components: {
  },
  data () {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    valid_email () {
      return /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/.test(this.email)
    },
    valid_password () {
      return !this.$_.isEmpty(this.password)
    }
  },
  mounted: function () {
  },
  methods: {
    login () {
      if (this.valid_email && this.valid_password) {
        const loader = this.$loading.show()
        this.$store.dispatch('login', { email: this.email, password: this.password }).then(() => {
          loader.hide()
          this.$router.push({ path: this.$route.query.redirect ? this.$route.query.redirect : '/' })
        }).catch(e => {
          loader.hide()
          this.$swal('Error', e.message ? e.message : e, 'error')
        })
      } else {
        this.$swal('Warning', '必填欄位不能為空', 'warning')
      }
    }
  }
}
</script>
